import directives from './directives.js';
import cookies from './cookies.js';
import filters from './filters.js';

export default {
  install: (Vue, options) => {
    for (let dir in directives) {
      if (directives.hasOwnProperty(dir)) {
        Vue.directive(dir, directives[dir])
      }
    }

    for (let func in cookies) {
      if (cookies.hasOwnProperty(func)) {
        Vue.prototype[`$${func}`] = cookies[func];
      }
    }

    for (let fil in filters) {
      if (filters.hasOwnProperty(fil)) {
        Vue.filter(fil, filters[fil])
      }
    }
  }
}

export const dispatchSearchGAEvent = (terms) => {
  if (window.gtag) {
    window.gtag('event', 'instant_search_result', {
      search_instant_term: terms
    });
  }
};