<template>
  <nav
    class="header-nav bg-white pt-px lg:pt-5 border-b border-geb-gray-100 md:border-geb-gray-200"
    ref="header"
    :aria-label="navLabel"
    role="navigation"
  >
    <div class="px-4 lg:-mt-0.5 lg:p-0 xl:container xl:mx-auto">
      <div class="flex justify-between h-13 lg:h-auto">
        <div
          class="flex flex-shrink-0 items-start md:items-center -ml-1.75 md:ml-0"
        >
          <slot name="logo"></slot>
        </div>
        <div class="hidden lg:flex lg:flex-grow">
          <div class="flex flex-col w-full gap-1">
            <div class="flex justify-end flex-grow -mt-px items-center gap-5">
              <div class="flex">
                <slot name="social-media"></slot>
              </div>
              <div class="flex justify-end items-center pr-3 gap-5.5">
                <slot name="tools"></slot>
              </div>
            </div>

            <div class="flex justify-between flex-grow -mt-px">
              <div class="flex header-nav__primary-list">
                <slot name="primary-list"></slot>
              </div>
              <div class="flex justify-end items-center pr-3 gap-5.5">
                <slot name="secondary-list"></slot>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile -->
        <div class="flex items-center gap-5 -mt-1 lg:hidden">
          <header-search
            :placeholder-search="placeholderSearch"
            :url-search="urlSearch"
            :aria-label="ariaLabel"
          ></header-search>
          <!-- Mobile menu button -->
          <button
            @click="toggleMenu"
            class="flex items-center justify-center rounded-md text-geb-dark-700 focus:outline-none"
          >
            <i
              v-if="!isOpen"
              class="block h-4.5 w-7"
              aria-hidden="true"
              v-icon:menu
            ></i>
            <i
              v-else
              class="block h-7 w-7"
              aria-hidden="true"
              v-icon:close-x
            ></i>
          </button>
        </div>
      </div>

      <div class="lg:hidden -mx-4 max-h-screen" v-show="isOpen">
        <div
          class="bg-black bg-opacity-50 w-screen min-h-screen fixed z-10"
        ></div>
        <div
          class="flex flex-col z-20 relative bg-geb-blue-secondary pt-3 pb-16 overflow-auto"
          ref="DisclosurePanel"
        >
          <slot name="mobile-primary-list"></slot>
          <slot name="mobile-secondary-list"></slot>
          <hr class="border-t border-geb-link border-opacity-30 mx-4" />
          <slot name="mobile-language"></slot>
          <hr class="border-t border-geb-link border-opacity-30 mx-4" />
          <slot name="mobile-social-media"></slot>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "../../EventBus.js";

export default {
  props: {
    urlSearch: {
      type: String,
      default: "/",
    },
    navLabel: {
      type: String,
      default: "Menú principal",
    },
    searchLabel: {
      type: String,
      default: "Buscador",
    },
    placeholderSearch: {
      type: String,
      default: "Buscar",
    },
    endpoint: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    ...mapActions({
      getMegamenus: "getMegamenusRequest",
    }),
    toggleMenu() {
      document
        .getElementsByTagName("body")[0]
        .classList.toggle("overflow-hidden");
      document
        .getElementsByTagName("body")[0]
        .classList.toggle("md:overflow-auto");
      this.isOpen = !this.isOpen;

      setTimeout(() => {
        this.fixDisclosurePanelHeight();
      }, 50);
    },
    fixDisclosurePanelHeight() {
      if (this.isOpen) {
        EventBus.$emit("close-search");
        const { DisclosurePanel } = this.$refs;
        DisclosurePanel.style.maxHeight = `${
          document.documentElement.clientHeight - DisclosurePanel.offsetTop
        }px`;
      }
    },
    getMegamenuContent() {
      this.getMegamenus(this.endpoint)
        .then(() => {
          setTimeout(() => {
            this.setItemsFocusEvent();
          }, 150);
        })
        .catch((err) => console.log(err));
    },
    setItemsFocusEvent() {
      const items = document.querySelectorAll(".header-nav__item--link");
      items.forEach((element) => {
        // element.firstChild.setAttribute("tabindex", "-1");
        // element.setAttribute("tabindex", "0");
        element.addEventListener("mouseover", () => {
          const { activeElement } = document;
          if (
            activeElement &&
            activeElement.classList.contains("header-nav__item--link-primary")
          ) {
            activeElement.blur();
            element.focus();
          }
        });
      });
    },
    onOpen() {
      const $a = this.$el.querySelectorAll("a");
      let current = null;
      $a.forEach((a) => {
        a.addEventListener("focus", () => {
          const li = a.closest(".header-nav__item--link");

          if (li) {
            if (!li.classList.contains("open"))
              current?.classList.remove("open");

            li.classList.add("open");

            current = li;
          }
        });
      });
      window.addEventListener("click", (e) => {
        if(current && e.pointerId === 1)
          current.classList.remove("open");
      });
    },
  },
  mounted() {
    this.getMegamenuContent();
    window.addEventListener("resize", this.fixDisclosurePanelHeight);
    EventBus.$on("close-menu", () => {
      if (this.isOpen) this.toggleMenu();
    });

    const bgImage = document.querySelector(".landing-page__background-image");
    if (!bgImage) {
      this.$refs.header.classList.add("has-shadow");
    }

    this.onOpen();
  },
};
</script>
